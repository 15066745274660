import HandlePhpFetch from "@/src/utils/fetch/PhpFetch";
import { JWT_COOKIE_NAME, VISITOR_COOKIE_NAME } from "@/config/constConf";
import { isServer } from "@/lib/commonService";
import Cookies from "js-cookie";
import { HandleJavaUsualFetch } from "@/src/utils/fetch";
import { getVistorId } from "./customerShow";

interface IGetSpecialSubProductsParams {
    pageId: string;
    params: Record<string, any>;
    header?: Record<string, string>;
    cookie?: Record<string, string>;
    is_secondary?: boolean;
    is_personalize?: boolean;
}

// 获取列表页镜片列表
export const getSpecialSubProducts = async ({
    pageId,
    params,
    header = {},
    cookie = {},
    is_secondary = false,
    is_personalize = false,
}: IGetSpecialSubProductsParams) => {
    const _data = params;
    _data.id && delete _data.id;
    const paramsObj = { page: 1, ..._data };
    const promises = [];

    if (is_personalize) {
        // 千人千面
        // 第一次没有visitor生成7位随机整数
        const tempId = Math.floor(1000000 + Math.random() * 9000000);
        const visitorID = getVistorId() || tempId;
        let intervalTime = -1;
        const intervalTimeCookie = isServer
            ? cookie["interval_time"]
            : Cookies.get("interval_time");

        intervalTime =
            intervalTimeCookie && JSON.parse(intervalTimeCookie)[pageId]
                ? new Date().getTime() - JSON.parse(intervalTimeCookie)[pageId]
                : -1;

        const currentHeader = {
            "interval-time": intervalTime.toString(),
            ...header,
        };

        promises.push(
            HandleJavaUsualFetch.get(
                `/personalize/${pageId}/search/${visitorID}`,
                paramsObj,
                { headers: currentHeader },
            ),
        );
    } else if (is_secondary) {
        // 二次营销
        promises.push(
            HandleJavaUsualFetch.get(
                `page/products/${pageId}/search`,
                paramsObj,
                { headers: header },
            ),
        );
    }

    if (promises.length === 0) {
        // 如果没有需要执行的请求，直接调用保底函数
        return HandlePhpFetch.get(`page/products/${pageId}`, paramsObj, {
            headers: header,
        });
    }
    const results = await Promise.all(promises);
    // 检查结果的 data 数组是否为空
    const isEmpty = results.every((response) => {
        // 二次营销报错或者未设置
        return response.code === 204;
    });
    if (isEmpty) {
        // 如果千人千面和二次营销都为空数组，则调用保底函数
        return HandlePhpFetch.get(`page/products/${pageId}`, paramsObj, {
            headers: header,
        });
    }
    return results.find((response_1) => Array.isArray(response_1.data));
};

const generateSixDigitRandomNumber = () => {
    const min = 100000; // 最小值为 100000（即 10^5）
    const max = 999999; // 最大值为 999999（即 10^6 - 1）

    return Math.floor(Math.random() * (max - min + 1)) + min;
};

// java的pageInfo接口，在服务端使用
export async function getPageInfoJava(
    pageId: string,
    cookiesObj: Record<string, string>,
) {
    const visitorId =
        cookiesObj[VISITOR_COOKIE_NAME] || generateSixDigitRandomNumber();
    const params = {
        pageId,
        visitorId,
        token: cookiesObj[JWT_COOKIE_NAME] ?? "",
    };

    const jwt = params.token;
    const headers: any = cookiesObj;
    if (jwt) headers["jwt-auth"] = jwt;
    return HandleJavaUsualFetch.get("/page/flow", params, {
        headers,
    });
}
